/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'cQ1PD8oQWTcMfNPmLQ6BF2.png': { uri: '/images/cQ1PD8oQWTcMfNPmLQ6BF2.png' },
'5eFTqcKzhiU4wJMcdwLGf9.png': { uri: '/images/5eFTqcKzhiU4wJMcdwLGf9.png' },
'wWfkceLYpBZTHB2w3Ly1Mp.png': { uri: '/images/wWfkceLYpBZTHB2w3Ly1Mp.png' },
'kUzfJuanib4aUbVckhdgxY.png': { uri: '/images/kUzfJuanib4aUbVckhdgxY.png' },
'uDpLpCUP43oZXho7ZzqnUJ.png': { uri: '/images/uDpLpCUP43oZXho7ZzqnUJ.png' },
'eNZr6Bs3WTnKpeGw2rs1fb.jpg': { uri: '/images/eNZr6Bs3WTnKpeGw2rs1fb.jpg' },
'kQboNPySjSwH3DNcoM7aM9.png': { uri: '/images/kQboNPySjSwH3DNcoM7aM9.png' },
'7avh2SePF9EVBE1AAuRAej.png': { uri: '/images/7avh2SePF9EVBE1AAuRAej.png' },
'9iftcw3P7ud1c9rbmZa1Gy.png': { uri: '/images/9iftcw3P7ud1c9rbmZa1Gy.png' },
'uigr3awNcvB22mEa7DNr9o.png': { uri: '/images/uigr3awNcvB22mEa7DNr9o.png' },
'2KtkgS3QsHxRe614dmhoDf.png': { uri: '/images/2KtkgS3QsHxRe614dmhoDf.png' },
'rMuXKsbKu9ertEKsbyHThr.png': { uri: '/images/rMuXKsbKu9ertEKsbyHThr.png' },
'8asNCsMQCsP6sGiKksQzVj.png': { uri: '/images/8asNCsMQCsP6sGiKksQzVj.png' },
'e2o7B9oEVwZY5GJiVwkxam.png': { uri: '/images/e2o7B9oEVwZY5GJiVwkxam.png' },
'kwcBYkVD9vccRoPx4Km9rw.png': { uri: '/images/kwcBYkVD9vccRoPx4Km9rw.png' },
'oEucQuNCQ7dAv81aCQhXhG.png': { uri: '/images/oEucQuNCQ7dAv81aCQhXhG.png' },
'7zxdMFuRBEbk6cSVtK4ZHL.png': { uri: '/images/7zxdMFuRBEbk6cSVtK4ZHL.png' },
'gWHg7To5dqnJ2d6Qn3Jque.png': { uri: '/images/gWHg7To5dqnJ2d6Qn3Jque.png' },
's85aHky5Lc6QnviWKtCXbS.png': { uri: '/images/s85aHky5Lc6QnviWKtCXbS.png' },
'6aeKgCkKaVveuMjAKdjvco.png': { uri: '/images/6aeKgCkKaVveuMjAKdjvco.png' },
'gNFgLvQkxFgUPBMvnjijiy.jpg': { uri: '/images/gNFgLvQkxFgUPBMvnjijiy.jpg' },
'rPM6awda4gHLR4zCo1iUGK.png': { uri: '/images/rPM6awda4gHLR4zCo1iUGK.png' },
'nzhPLvTzecLxd5rzaLMx84.png': { uri: '/images/nzhPLvTzecLxd5rzaLMx84.png' },
'dHYieXPn22M2cLvCVEw3yf.png': { uri: '/images/dHYieXPn22M2cLvCVEw3yf.png' },
'mvUbfuDxPdajixMhgzNcKW.png': { uri: '/images/mvUbfuDxPdajixMhgzNcKW.png' },
'4jt6fe9v2mibSupv4Ttb6x.png': { uri: '/images/4jt6fe9v2mibSupv4Ttb6x.png' },
'9zDFsir4wyhYiwvmr7GiWB.png': { uri: '/images/9zDFsir4wyhYiwvmr7GiWB.png' },
'fiTrtqroPTrFHrrrjBzE9K.png': { uri: '/images/fiTrtqroPTrFHrrrjBzE9K.png' },
'9QG6KRrnu8njYBbi179f6S.png': { uri: '/images/9QG6KRrnu8njYBbi179f6S.png' },
'urzdZxYwMqpDF4bk1zHSkx.png': { uri: '/images/urzdZxYwMqpDF4bk1zHSkx.png' },
'tmdbpMDdUZcbJ7kYTtJynz.png': { uri: '/images/tmdbpMDdUZcbJ7kYTtJynz.png' },
'qhMMtM6H6WXK1Af5SJgZLS.png': { uri: '/images/qhMMtM6H6WXK1Af5SJgZLS.png' },
'rji4AKJ8zDv8eey1qzeXVj.png': { uri: '/images/rji4AKJ8zDv8eey1qzeXVj.png' },
'bp6qgtnbaJSjHHaDcL3sKX.png': { uri: '/images/bp6qgtnbaJSjHHaDcL3sKX.png' },
'dcvNKoF6LtSgnsJNdmozek.png': { uri: '/images/dcvNKoF6LtSgnsJNdmozek.png' },
'nE2rwKTiy1Y8j5F8VJHSny.png': { uri: '/images/nE2rwKTiy1Y8j5F8VJHSny.png' },
'9EtvgCfMJ3PhaRy7wctD1e.png': { uri: '/images/9EtvgCfMJ3PhaRy7wctD1e.png' },
'mCy8UMPfiyNvTdr4rrmoUZ.png': { uri: '/images/mCy8UMPfiyNvTdr4rrmoUZ.png' },
'aw6LC6HDGkocRnziWoYgZT.png': { uri: '/images/aw6LC6HDGkocRnziWoYgZT.png' },
'43qgBjwYTaPvsPupurjmBh.png': { uri: '/images/43qgBjwYTaPvsPupurjmBh.png' },
'g6y1CUrKz3zWq89kaZ9HuH.png': { uri: '/images/g6y1CUrKz3zWq89kaZ9HuH.png' },
'3NbpmJXNNXb52rNTDYNojD.jpg': { uri: '/images/3NbpmJXNNXb52rNTDYNojD.jpg' },
'5zieeipez8Gu6GAjN3wjZi.jpg': { uri: '/images/5zieeipez8Gu6GAjN3wjZi.jpg' }
}

export default imageStaticSourcesByFileName
